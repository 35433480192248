import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 例外ユーザーのタグ情報一覧
    tags: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 例外ユーザーのタグ情報一覧
   */
  tags: state => state.tags,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} taid タグのID
   * @return {Object} タグ情報
   */
  tag: state => taid => state.tags.find(tag => tag.taid === taid)
}

const mutations = {
  /**
   * 例外ユーザーのタグ情報一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} tags 例外ユーザーのタグ情報一覧
   */
  setTags: (state, tags) => {
    state.tags = tags
  },
  /**
   * 例外ユーザーのタグ情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} newTag 例外ユーザーのタグ情報
   */
  setTag: (state, newTag) => {
    state.tags.find(tag => tag.taid === newTag.taid) ?
      state.tags = state.tags.map(tag => tag.taid === newTag.taid ? newTag : tag) :
      state.tags.push(newTag)
  },
  /**
   * 例外ユーザーのタグ情報をstateから削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} taid タグのID
   */
  deleteTag: (state, taid) => {
    state.tags = state.tags.filter(tag => tag.taid !== taid)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 例外ユーザーのタグ情報一覧を取得
   */
  getTags: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('tags')
        .get()
      const tags = []
      snapshot.forEach(doc => {
        tags.push(Object.assign(doc.data(), { taid: doc.id }))
      })
      commit('setTags', tags)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外ユーザーのタグ作成
   * @param {Object} params 引数
   */
  addTag: async ({ commit }, params) => {
    try {
      const ref = await firestore.collection('tags').add(params)
      commit('setTag', Object.assign(params, { taid: ref.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外ユーザーのタグ編集
   * @param {Object} payload 引数
   * @param {String} payload.taid タグのID
   * @param {Object} payload.params 更新内容
   */
  updateTag: async ({ commit }, payload) => {
    try {
      await firestore.collection('tags')
        .doc(payload.taid)
        .update(payload.params)
      commit('setTag', Object.assign(payload.params, { taid: payload.taid }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外ユーザーのタグ情報を削除
   * @param {String} taid タグのID
   */
  deleteTag: async ({ commit }, taid) => {
    try {
      await firestore
        .collection('tags')
        .doc(taid)
        .delete()
      commit('deleteTag', taid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
