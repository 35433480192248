import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全ユーザーのオブジェクト一覧
    // { uid: {}, uid: {}, ... }
    users: {},
    // 全件取得済みかどうか
    hasGot: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 全ユーザーのオブジェクト一覧
   */
  users: state => state.users,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  user: state => uid => state.users[uid] ? state.users[uid] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} フォロー中情報を取得済みかどうか
   */
  hasGot: state => state.hasGot
}

const mutations = {
  /**
   * ユーザー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.user ユーザーのオブジェクト
   */
  setUser: (state, payload) => {
    state.users = Object.assign({}, state.users, { [payload.uid]: payload.user })
  },
  /**
   * stateのユーザー情報を更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.user ユーザーのオブジェクト
   */
  updateUser: (state, payload) => {
    Object.keys(payload.user).forEach(key => {
      state.users[payload.uid][key] = payload.user[key]
    })
  },
  /**
   * フォロー中情報が取得済みかどうか
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} hasGot 取得済みかどうか
   */
  setHasGot: (state, hasGot) => {
    state.hasGot = hasGot
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 削除済みを含めた全ユーザー情報を取得
   * @return {Object} ユーザー情報
   */
  getUsers: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('users')
        .get()
      snapshot.forEach(doc => {
        commit('setUser', { uid: doc.id, user: Object.assign(doc.data(), { uid: doc.id }) })
      })
      commit('setHasGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー情報を取得
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  getUser: async ({ commit }, uid) => {
    try {
      const doc = await firestore
        .collection('users')
        .doc(uid)
        .get()
      const user = doc.exists ? doc.data() : null
      if (user) commit('setUser', { uid: doc.id, user: Object.assign(doc.data(), { uid: doc.id }) })
      return user
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 自分を除く管理者一覧の取得
   * @return {Object} 管理者一覧 { uid: {}, uid: {}, ... }
   */
  getAdmins: async ({ commit, rootGetters }) => {
    try {
      const snapshot = await firestore
        .collection('users')
        .where('authority', '==', 'admin')
        .get()
      const admins = {}

      // 自分自身は除くため自分のuidを取得しておく
      const uid = rootGetters['auth/uid']

      // 管理者情報を返り値に合わせてフォーマット
      snapshot.forEach(doc => {
        if (uid !== doc.id) admins[doc.id] = doc.data()
      })
      return admins
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー情報を更新する
   * @param {Object} payload ユーザー情報
   * @param {String} payload.uid 更新するユーザーのID
   * @param {Object} payload.params 更新情報
   */
  updateUser: async ({ commit }, payload) => {
    try {
      await firestore
        .collection('users')
        .doc(payload.uid)
        .update(payload.params)
      commit('updateUser', { uid: payload.uid, user: payload.params })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
