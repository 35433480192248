import { firebase } from '@/firebase'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * メールアドレスからユーザー情報を取得する
   * @param {String} email メールアドレス
   * @return {Object} { email: メールアドレス, uid: ユーザーID, user: ユーザー情報 }
   */
  getUserByEmail: async ({ commit }, email) => {
    try {
      const get = firebase.functions(REGION).httpsCallable('getUserByEmail')
      const results = await get({ email: email })
      if (results.data.user) commit('users/setUser', { uid: results.data.uid, user: results.data.user }, { root: true })
      return results.data
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * firestoreのusersに紐づくメールアドレスを全取得する
   */
  getAllEmails: async ({ commit }) => {
    try {
      const getAllEmails = firebase.functions(REGION).httpsCallable('getAllEmails')
      const result = await getAllEmails()
      commit('setEmails', result.data, { root: true })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * メールを送信
   * @param {Object} payload メール情報
   * @param {String} payload.subject メールのタイトル
   * @param {String} payload.content メールの本文（改行コード含む）
   * @param {String} payload.email メール送信先のメールアドレス
   */
  sendEmail: async ({ commit }, payload) => {
    try {
      const send = firebase.functions(REGION).httpsCallable('sendEmail')
      await send(payload)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定ユーザーにメールを一斉送信
   * @param {String} payload メール情報
   * @param {String} payload.subject メールのタイトル
   * @param {String} payload.content メールの本文（改行コード含む）
   * @param {String} payload.to 送信先 [ all: auth認証がある全員 / users: auth認証かつusersにデータがある全員 ]
   */
  sendEmailAll: async ({ commit }, payload) => {
    try {
      const send = firebase.functions(REGION).httpsCallable('sendEmailAll')
      await send({
        subject: payload.subject,
        content: payload.content,
        to: payload.to
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザーのauth情報を削除する
   * @param {String} uid authを削除するユーザーのID
   */
  deleteAuthUser: async ({ commit }, uid) => {
    try {
      const deleteAuthUser = firebase.functions(REGION).httpsCallable('deleteAuthUser')
      await deleteAuthUser(uid)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * カスタムクレイムのセット
   * @param {Object} payload 引数
   * @param {String} paylod.uid 設定するユーザーのid
   * @param {Object} paylod.params 設定する値
   */
  setCustomClaims: async ({ commit }, payload) => {
    try {
      const setCustomClaims = firebase.functions(REGION).httpsCallable('setCustomClaims')
      await setCustomClaims({ uid: payload.uid, params: payload.params })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 全ユーザーの定期支払の回収の停止、再開を行う
   * @param {Boolean} isPauseCollection 回収停止かどうか true:回収停止 false:回収再開
   * @return {void}
   */
  switchSubscriptionCollections ({ commit }, isPauseCollection) {
    return new Promise(resolve => {
      const switchSubscriptionCollections = firebase.functions(REGION).httpsCallable('switchSubscriptionCollections')
      switchSubscriptionCollections({ isPauseCollection: isPauseCollection }).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * メンテナンス用のrulesに変更してDBアクセスを停止
   * @return {void}
   */
  setMaintenanceRules ({ commit }) {
    return new Promise(resolve => {
      const setMaintenanceRules = firebase.functions(REGION).httpsCallable('setMaintenanceRules')
      setMaintenanceRules().then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * 定期支払の回収一時停止中に発行されたインボイスの自動収集をオンにする
   * 回収漏れ発生の可能性が出るため、全ユーザーの定期支払の回収を再開した後に起動すること
   * @return {void}
   */
  restartInvoices ({ commit }) {
    return new Promise(resolve => {
      const restartInvoices = firebase.functions(REGION).httpsCallable('restartInvoices')
      restartInvoices().then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
