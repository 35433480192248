import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      component: loadComponent('Admin.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/add',
      name: 'admin_add',
      component: loadComponent('AdminAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/delete',
      name: 'admin_delete',
      component: loadComponent('AdminDelete.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/group',
      name: 'group',
      component: loadComponent('Group.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/group/new',
      name: 'group_new',
      component: loadComponent('GroupNew.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/group/cancel',
      name: 'group_cancel',
      component: loadComponent('GroupCancel.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/group/member',
      name: 'group_member',
      component: loadComponent('GroupMember.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/mail',
      name: 'mail',
      component: loadComponent('Mail.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/mail/all',
      name: 'mail_all',
      component: loadComponent('MailAll.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception',
      name: 'exception',
      component: loadComponent('Exception.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception/add',
      name: 'exception_add',
      component: loadComponent('ExceptionAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception/edit',
      name: 'exception_edit',
      component: loadComponent('ExceptionEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exception/tag',
      name: 'exception_tag',
      component: loadComponent('ExceptionTag.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting',
      name: 'setting',
      component: loadComponent('Setting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/image',
      name: 'setting_image',
      component: loadComponent('SettingImage.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting/privilege',
      name: 'setting_privilege',
      component: loadComponent('SettingPrivilege.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: loadComponent('Maintenance.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance/start',
      name: 'maintenance_start',
      component: loadComponent('MaintenanceStart.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance/end',
      name: 'maintenance_end',
      component: loadComponent('MaintenanceEnd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    // Auth処理中の場合は遷移をキャンセルさせる
    autoTransition ? next() : next(false)
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'login' }) : next()
  } else {
    // ログイン済みの場合
    requiresAuth ? next() : next({ name: 'home' })
  }
})

/**
 * beforeEachで遷移が確定した後の処理
 */
router.beforeResolve((to, from, next) => {
  const isAuthProcessing = store.getters.isAuthProcessing

  // 遷移先と遷移元が異なればisProcessingをtrueにする
  if (!isAuthProcessing && to.name !== from.name) store.commit('setProcessing', true)
  next()
})

export default router
