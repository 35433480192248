<template>
  <v-app id="app">
    <original-header v-if="!isAuthProcessing" />
    <router-view v-if="!isAuthProcessing" v-show="!isSubmitting" />
    <processing v-if="isProcessing || isSubmitting" />
    <telop v-if="telop.show" />
  </v-app>
</template>

<script>
import OriginalHeader from '@/components/common/header.vue'
import Processing from '@/components/common/processing.vue'
import Telop from '@/components/common/telop'

export default {
  components: { OriginalHeader, Processing, Telop },
  async mounted () {
    // authの監視
    this.$store.dispatch('auth/onAuth')
  },
  computed: {
    /**
     * @return {Boolean} 初期処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} フォーム等で情報を更新中かどうか
     */
    isSubmitting () {
      return this.$store.getters.isSubmitting
    },
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters.telop
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/color.scss';

html {
  font-size: 10px !important;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black_color;
  background-color: $white_color;
}

a {
  color: $black_color;
  text-decoration: none;
  outline: none;
}
</style>
