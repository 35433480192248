<template>
  <div class="home" v-if="!isProcessing">
    <div class="home__menu" v-for="item in items" :key="item.title">
      <home-menu :item="item" />
    </div>
  </div>
</template>

<script>
import HomeMenu from '@/components/common/menu.vue'

export default {
  name: 'home',
  components: { HomeMenu },
  data () {
    return {
      items: [
        { title: '管理者権限', content: '管理者権限に関する処理ができます。', to: 'admin' },
        { title: 'FBグループ加入状況', content: 'Facebookグループの加入状況を管理できます。', to: 'group' },
        { title: 'メールの送信', content: 'ユーザーにメールを送信することができます。', to: 'mail' },
        { title: '例外ユーザーの処理', content: '年払いなど例外ケースで登録したユーザーの管理ができます。', to: 'exception' },
        { title: '設定', content: '入会特典などの文言修正ができます。', to: 'setting' },
        { title: 'メンテナンス', content: 'メンテナンスの開始・終了ができます。', to: 'maintenance' },
        { title: 'ログアウト', content: '押下するとログアウトされます。', func: () => this.signout() }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * サインアウト
     */
    signout () {
      this.$store.commit('setSubmitting', true)
      this.$store.dispatch('auth/signout').then(result => {
        // サインアウト成功時はログイン画面へ、失敗場合はエラー画面へ
        result.status === 'success' ? this.$router.push({ name: 'login' }) : this.$router.push({ name: 'error' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.home {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: 0 $padding_width $padding_height;
  margin: $header_height auto 0 auto;
}
</style>
